var generic = generic || {};
var site = site || {};
var settings = Drupal.settings || {};

site.userInfoCookie = site.userInfoCookie || {};
site.userInfoCookie.getValue =
  site.userInfoCookie.getValue ||
  function () {
    return '';
  };
Drupal.behaviors.siteHeaderFormatterV1 = (function ($) {
  var $html = $();
  var $body = $();
  var $template = $();
  var $bottomLinksWrapper = $();
  var $bottomLinks = $();
  var $gnavOffers = $();
  var $searchIcons = $();
  var $mobileSearchInput = $();
  var $gnavSearchIcon = $();
  var $gnavSearchSection = $();
  var $gnavHamburger = $();
  var $gnavHamburgerInput = $();
  var $gnavMainSectionWrapper = $();
  var $gnavMobileSectionsMenu = $();
  var $gnavMainSection = $();
  var $gnavMainSectionContent = $();
  var $gnavMainSectionTrigger = $();
  var $mainSectionTriggerReset = $();
  var $triggerResetLabel = $();
  var $subSectionTriggerReset = $();
  var $linksTriggerReset = $();
  var $allSectionGroups = $();
  var $allSectionTriggerResetLabels = $();
  var $allSectionGroupContent = $();
  var $allSectionGroupsTrigger = $();
  var $allSectionGroupsMobTrigger = $();
  var $gnavSectionGroup = $();
  var $allGnavLinks = $();
  var $gnavLinkTout = $();
  var $gnavLinkToutLabel = $();
  var $gnavLinkToutContent = $();
  var $staticLinks = $();
  var $accountSignedIn = $();
  var $accountContent = $();
  var $accountGreetingText = $();
  var $accountUserName = $();
  var $emailSignupLinks = $();
  var emailAnchoredLink = false;

  // Calculate desktop for mobile menu stuff.
  var bps = Unison.fetch.all();
  var bp = Unison.fetch.now();
  var isMediaLandscape = window.matchMedia('(orientation: landscape)').matches ? true : false;
  var isDesktop = parseInt(bp.width, 10) >= parseInt(bps.landscape, 10) && isMediaLandscape;
  var position = $(window).scrollTop();
  var offersHeight = 0;
  var hideHeaderOnScroll = false;
  var linksToNavigate =
    'a, button, input, select, textarea, iframe, object, embed, *[tabindex], *[contenteditable]';
  var tabbableElements =
    'a:tabbable, button:tabbable, input:tabbable, select:tabbable, textarea:tabbable, iframe:tabbable, object:tabbable, embed:tabbable, *[tabindex]:tabbable';
  var noTabbableElements = '[tabindex=-1], [disabled], [aria-hidden=true]';
  var timer;
  var behavior = {
    attached: false,
    attach: function (context) {
      if (this.attached) {
        return;
      }
      this.attached = true;
      $html = $('html');
      $body = $('body');
      $template = $('.js-site-header-formatter-v1', context);
      $searchIcons = $('.js-esearch-open-icon', context);
      $mobileSearchInput = $('.js-site-header-formatter-search-icon-wrapper', context);
      $gnavSearchIcon = $('.js-site-header-formatter__search-icon', $template);
      $gnavSearchSection = $('.js-header-nav-section__section-search', $template);
      $bottomLinksWrapper = $('.js-site-header-formatter-links-container', context);
      $bottomLinks = $('a', $bottomLinksWrapper);
      $emailSignupLinks = $('.js-header-email-signup-link', $template);

      $gnavOffers = $('.site-header-formatter__offers', $template);
      $gnavHamburger = $('.js-gnav-mobile-trigger', $template);
      $gnavHamburgerInput = $('.js-site-header-formatter__trigger-input', $template);
      $gnavMainSectionWrapper = $('.js-site-header-formatter__sections', $template);
      $gnavMobileSectionsMenu = $(
        '.js-site-header-formatter__mobile-menu-header',
        $gnavMainSectionWrapper
      );
      $gnavMainSection = $('.js-header-nav-section__section-v1', $gnavMainSectionWrapper);
      $gnavMainSectionContent = $('.js-header-nav-section__section-content', $gnavMainSection);
      $gnavMainSectionTrigger = $('.js-header-nav-section__section-label', $gnavMainSection);
      $mainSectionTriggerReset = $('.js-site-header-formatter__trigger-reset', $template);
      $triggerResetLabel = $('.js-gnav-section-trigger-reset', $template);
      $subSectionTriggerReset = $('.js-site-header-formatter-subsection-trigger-reset', $template);
      $linksTriggerReset = $('.js-site-header-formatter-links-trigger-reset', $template);
      // These elements are in gnav_section_formatter_v1, we collapse them too when gnav is closed.
      $allSectionGroups = $('.js-gnav-section-formatter-v1', $template);
      $allSectionTriggerResetLabels = $allSectionGroups.find(
        '.js-gnav-section-formatter__link--trigger'
      );
      $allSectionGroupContent = $('.js-gnav-section-formatter__content-wrapper', $allSectionGroups);
      $allSectionGroupsTrigger = $(
        '.js-gnav-section-formatter-trigger, .js-gnav-section-formatter-mob-trigger',
        $allSectionGroups
      );
      $allSectionGroupsMobTrigger = $('.js-gnav-section-formatter-mob-trigger', $allSectionGroups);
      $gnavSectionGroup = $('.js-gnav-section-group-v1', $template);
      $staticLinks = $('.js-gnav-section-group-static-link-trigger', $gnavMainSectionWrapper);

      // These elements are in the second panel gnav-links with tout to display in the third panel for desktop.
      $allGnavLinks = $('.js-gnav-section-formatter__content-wrapper', $template);
      $gnavLinkTout = $('.js-gnav-link-tout', $allGnavLinks);
      $gnavLinkToutLabel = $allGnavLinks.find('.js-gnav-link-tout__section-label');
      $gnavLinkToutContent = $('.js-gnav-link-tout-content', $allGnavLinks);

      hideHeaderOnScroll = $template.hasClass('hide-site-header-on-scroll');
      offersHeight = $gnavOffers.outerHeight(true);

      // Account Signed In
      $accountSignedIn = $('.js-site-header-formatter-signin', $template);
      $accountContent = $('.js-site-header-formatter-account', $template);
      $accountGreetingText = $('.js-site-header-formatter-link-greeting-text', $accountContent);
      $accountUserName = $('.js-account-username', $accountGreetingText);

      if ($.cookie('hide_header_offer_banner')) {
        $template.toggleClass('header-offers-banner-hidden', true);
      }
      $(document).on('user.loaded', function (event, val) {
        var $loyaltyJoin = $('.js-site-header-formatter-loyalty-join', $template);
        var $loyaltyJoined = $('.js-site-header-formatter-loyalty-joined', $template);
        var $loyaltyTextJoined = $('.js-gnav-loyalty-text', $loyaltyJoined);
        var $loyaltyPtsJoined = $('.js-gnav-loyalty-pts', $loyaltyJoined);
        var $loyaltyAccountLink = $('.js-account-links__loyalty', context);
        var pointsPrefixSpace = '';
        var loyaltyCopy = '';
        var userFirstName = site.userInfoCookie.getValue('first_name');
        var firstname = userFirstName ? userFirstName + ',' : '';
        var isLoyaltyPricesEnabled =
          Drupal.settings &&
          Drupal.settings.common &&
          Drupal.settings.common.loyalty_prices_enabled;
        var showLoyaltyPoints = settings.show_points_for_loyalty ? 'points' : 'current_qualifying';
        var accountHeaderText = $accountGreetingText.data('account-header');

        if (val.signed_in) {
          if (
            !_.isUndefined(userFirstName) &&
            !_.isNull(userFirstName) &&
            $accountGreetingText.length > 0
          ) {
            $accountUserName.html(userFirstName);
            $accountSignedIn.addClass('hidden');
            $accountContent.removeClass('hidden');
          } else if (accountHeaderText) {
            $accountGreetingText.html(accountHeaderText);
            $accountSignedIn.addClass('hidden');
            $accountContent.removeClass('hidden');
          }
        }

        if (!val.is_pro) {
          $loyaltyJoin.removeClass('hidden');
          if (val.signed_in) {
            if (isLoyaltyPricesEnabled) {
              $loyaltyJoin.addClass('hidden');
              $loyaltyJoined.removeClass('hidden');
              loyaltyCopy = firstname + pointsPrefixSpace + $loyaltyTextJoined.first().text();
              $loyaltyTextJoined.text(loyaltyCopy);
            } else if (val.is_loyalty_member) {
              $loyaltyAccountLink.removeClass('hidden');
              $loyaltyJoin.addClass('hidden');
              $loyaltyJoined.removeClass('hidden');
              loyaltyCopy = site.userInfoCookie.getValue(showLoyaltyPoints)
                ? site.userInfoCookie.getValue(showLoyaltyPoints)
                : '';
              loyaltyCopy = loyaltyCopy + pointsPrefixSpace + $loyaltyPtsJoined.first().text();
              $loyaltyPtsJoined.text(loyaltyCopy);
            }
          }
        }
      });

      function _updateNavStatus() {
        var scroll = $(window).scrollTop();
        var headerHeight = $template.outerHeight(true);
        var headerBarHeight = headerHeight - offersHeight;
        var noSticky = $body.hasClass('site-header-formatter--large-logo')
          ? headerHeight
          : offersHeight;

        // If the user scroll down, hide the Nav.
        if (scroll <= position) {
          $body.toggleClass('site-header-formatter--hidden', false);
          $body.toggleClass('site-header-formatter--after-pos', false);
        } else if (scroll > headerHeight) {
          $body.toggleClass('site-header-formatter--hidden', true);
          $body.toggleClass('site-header-formatter--sticky', true);
          $body.addClass('sticky-header-opened');
          if (position > headerHeight + 10) {
            $body.toggleClass('site-header-formatter--after-pos', true);
          }
        }

        if (scroll <= noSticky) {
          $body.toggleClass('site-header-formatter--sticky', false);
          $body.removeClass('sticky-header-opened');
        }

        if (scroll <= headerBarHeight * 2 && scroll >= headerHeight && scroll <= position) {
          $body.addClass('site-header-formatter--up');
        } else if (
          $body.hasClass('site-header-formatter--up') &&
          (position > headerBarHeight * 2 || scroll <= headerHeight)
        ) {
          $body.removeClass('site-header-formatter--up');
          if (
            scroll <= headerHeight &&
            $body.hasClass('site-header-formatter--large-logo') &&
            !$body.hasClass('site-header-formatter--hidden') &&
            !$body.hasClass('site-header-formatter--sticky')
          ) {
            $('body, html').animate({ scrollTop: 0 }, 500);
          }
        }

        position = scroll;
      }

      // close the nav.
      function _closeGnav(keepActive) {
        $html.toggleClass('active-gnav', !!keepActive).delay(1000);
        $gnavMainSectionWrapper.attr('aria-expanded', !!keepActive || isDesktop);
        $gnavMainSectionWrapper.toggleClass('content-panel-visible', !!keepActive).delay(1000);
        $gnavMainSectionWrapper.toggleClass('search-panel-visible', !!keepActive);
        // Reset Header Nav Section elements state.
        $gnavMainSection.attr('aria-expanded', 'false');
        $gnavMainSectionContent.attr('aria-hidden', 'true');

        $(document).trigger('close_cart_confirm_overlay');

        // Reset Section Formatter elements state.
        $allSectionGroups.attr('aria-expanded', 'false');
        $allSectionGroupContent.attr('aria-hidden', 'true');
        $gnavSectionGroup.removeClass('active-section-group');
        $subSectionTriggerReset.prop('checked', true);
        $gnavMainSectionContent.removeClass('gnav-second-panel');
        $allSectionGroupsMobTrigger.prop('checked', false);

        // Reset Links elements state for the second panel.
        $linksTriggerReset.prop('checked', true);
        $allGnavLinks.attr('aria-expanded', 'false');
        $gnavLinkToutContent.attr('aria-hidden', 'true');

        if (!keepActive) {
          $mainSectionTriggerReset.prop('checked', true);

          if (!isDesktop) {
            $gnavHamburger.prop('checked', false);
          }
        }
      }

      function _gnavHeaderTrigger() {
        // Calculate desktop for mobile menu stuff.
        if (!!isDesktop) {
          $gnavMainSectionTrigger
            .off('mouseover.siteHeader click.siteHeader touchstart.siteHeader')
            .on('click.siteHeader touchstart.siteHeader mouseover.siteHeader', function (e) {
              var $self = $(this);
              var isActiveGnav = $('html.active-gnav').length === 1 ? true : false;

              //Hover trigger only on active gnav
              if (e.type === 'mouseover' && isActiveGnav) {
                _gnavPcTrigger($self, e);
              } else if (e.type === 'click' || e.type === 'touchstart') {
                _gnavPcTrigger($self, e);
              }
            });
        } else {
          $gnavMainSectionTrigger
            .off('mouseover.siteHeader click.siteHeader touchstart.siteHeader')
            .on('click.siteHeader', function (e) {
              var $self = $(this);

              _gnavMobileTrigger($self, e);
            });
        }
      }

      function _gnavPcTrigger($self, e) {
        var $parent = $self.parents('.js-header-nav-section__section-v1');
        var $input = $parent.prev();
        var $content = $parent.find('.js-header-nav-section__section-content');
        var $subNav = $content.find('.js-gnav-section-formatter-v1').first();
        var $subNavcontent = $subNav.find('.js-gnav-section-formatter__content-wrapper');
        var $searchSection = $self.parents('.js-header-nav-section__section-search');
        var isOnSearchSection = $searchSection.length > 0 ? true : false;

        var keepActive = $content.length ? true : false;

        if ($input.prop('checked') === true) {
          return;
        }
        _closeGnav(keepActive);
        if ($content.length) {
          e.preventDefault();
          $input.prop('checked', true);
          $html.toggleClass('active-utility-overlay', false);
          $html.toggleClass('active-gnav', true);
          $parent.attr('aria-expanded', 'true');
          $content.attr('aria-hidden', 'false');
          $subNav.attr('aria-expanded', 'true');
          $subNavcontent.attr('aria-hidden', 'false');
          $gnavMainSectionWrapper.toggleClass('search-panel-visible', isOnSearchSection);

          $allSectionGroups.attr('aria-expanded', 'false');
          $allSectionGroupContent.attr('aria-hidden', 'true');
          $gnavSectionGroup.removeClass('active-section-group');
          $allGnavLinks.attr('aria-expanded', 'false');
          $gnavLinkToutContent.attr('aria-hidden', 'true');
        }
      }

      function _gnavMobileTrigger($self, e) {
        var $parent = $self.parents('.js-header-nav-section__section-v1');
        var $input = $parent.prev();
        var $content = $parent.find('.js-header-nav-section__section-content');
        var $subNav = $parent.find('.js-gnav-section-formatter-v1');
        var $searchSection = $self.parents('.js-header-nav-section__section-search');
        var isOnSearchSection = $searchSection.length > 0 ? true : false;

        _closeGnav(true);
        if (!$content.length) {
          return;
        }
        e.preventDefault();
        $input.prop('checked', true);
        $html.toggleClass('active-utility-overlay', false);
        $html.toggleClass('active-gnav', true);
        $gnavMainSectionWrapper.attr('aria-expanded', 'true');
        $subNav.attr('aria-expanded', 'true');
        $parent.attr('aria-expanded', 'true');
        $content.attr('aria-hidden', 'false');
        $gnavMainSectionWrapper.toggleClass('search-panel-visible', isOnSearchSection);
        // Reset Section Formatter elements state.
        if (!isDesktop) {
          $allSectionGroups.attr('aria-expanded', 'false');
          $allSectionGroupContent.attr('aria-hidden', 'true');
          $gnavSectionGroup.removeClass('active-section-group');
        }
        $allGnavLinks.attr('aria-expanded', 'false');
        $gnavLinkToutContent.attr('aria-hidden', 'true');
      }

      function _toggleMobileMenu() {
        // If mobile content is open then reset radio buttom for PC.
        $mainSectionTriggerReset.prop('checked', true);
        if ($gnavMainSectionWrapper.attr('aria-expanded') === 'false') {
          $html.toggleClass('active-gnav', true);
          $gnavMainSectionWrapper.attr('aria-expanded', 'true');
        } else {
          _closeGnav();
        }
      }

      // Subnav updates using js only.
      if (isDesktop) {
        $gnavMainSectionWrapper.attr('aria-expanded', 'true');
      }
      // Clone Secondary Links and append to Header Nav Section to display the respective position according accordion.
      $bottomLinksWrapper
        .clone()
        .addClass('header-nav-section__mob-bottomlinks')
        .appendTo('.js-header-nav-section__section-content');

      if (hideHeaderOnScroll) {
        $(window)
          .off('scroll.gnavHeaderBlock')
          .on('scroll.gnavHeaderBlock', _.throttle(_updateNavStatus, 300));
        _updateNavStatus();
      }

      _gnavHeaderTrigger();

      Unison.on('change', function () {
        bp = Unison.fetch.now();
        offersHeight = $gnavOffers.outerHeight(true);
        isMediaLandscape = window.matchMedia('(orientation: landscape)').matches ? true : false;
        isDesktop = parseInt(bp.width, 10) >= parseInt(bps.landscape, 10) && isMediaLandscape;
        _closeGnav();
        _gnavHeaderTrigger();
      });

      $triggerResetLabel.on('click.siteHeader', function () {
        if (isDesktop) {
          _closeGnav();
        }
      });

      // Mobile only icon takes user back to main gnav sections display and collapses all open sections.
      $gnavMobileSectionsMenu.once().on('click', function () {
        _closeGnav(true);
      });

      $staticLinks.off('mouseover mouseout').on('mouseover mouseout', function (event) {
        var $self = $(this);
        var $parent = $self.parents('.js-gnav-section-group-v1');
        var $content = $self.closest('.js-header-nav-section__section-content');
        var outLink = event.type === 'mouseout';
        $gnavSectionGroup.not($parent).removeClass('active-section-group');

        if (
          outLink &&
          $(event.relatedTarget).hasClass('js-gnav-section-formatter__link--trigger')
        ) {
          return;
        }
        $linksTriggerReset.prop('checked', true);
        $allGnavLinks.attr('aria-expanded', 'false');
        $gnavLinkToutContent.attr('aria-hidden', 'true');
        $subSectionTriggerReset.prop('checked', true);
        $content.removeClass('gnav-second-panel');
        $parent.toggleClass('active-section-group', !outLink);
      });
      $bottomLinks.off('mouseover').on('mouseover', function () {
        $gnavMainSectionContent.removeClass('gnav-second-panel');
        $gnavSectionGroup.removeClass('active-section-group');
        $linksTriggerReset.add($subSectionTriggerReset).prop('checked', true);
        $allGnavLinks.attr('aria-expanded', 'false');
        $gnavLinkToutContent.attr('aria-hidden', 'true');
      });

      if ($emailSignupLinks.length > 0) {
        emailAnchoredLink =
          $emailSignupLinks.attr('href').substr(0, 2) === '/#' ||
          $emailSignupLinks.attr('href').substr(0) === '#';

        if (emailAnchoredLink) {
          $emailSignupLinks.once().on('click', function (e) {
            e.preventDefault();
            $('html, body').animate({
              scrollTop:
                $(document).height() -
                $('.sitewide-footer-formatter').height() +
                $('.js-newsletter-section').position().top
            });
          });
        }
      }

      function _toggleSectionGroup(item) {
        var $self = $(item);
        var $parent = $self.parents('.js-gnav-section-group-v1');
        var expand = $self.is(':checked');
        var $mainHeaderNav = $self.closest('.js-header-nav-section__section-content');
        var $siblingSectionGroupContent = $self.siblings(
          '.js-gnav-section-formatter__content-wrapper'
        );
        var $parentSectionGroup = $self.closest('.js-gnav-section-formatter-v1');

        if (!isDesktop && $self.hasClass('js-gnav-section-formatter-mob-trigger')) {
          $allSectionGroupsMobTrigger.not($self).prop('checked', false);
        }

        $linksTriggerReset.prop('checked', true);
        $allGnavLinks.attr('aria-expanded', 'false');
        $gnavLinkToutContent.attr('aria-hidden', 'true');
        $gnavLinkTout.removeClass('active-links-group');
        $allSectionGroupContent.attr('aria-expanded', 'false');
        $allSectionGroupContent.attr('aria-hidden', 'true');
        $allSectionGroups.attr('aria-expanded', 'false');
        $gnavSectionGroup.removeClass('active-section-group');

        // Open the respective Section Group Content
        $siblingSectionGroupContent.attr('aria-expanded', expand);
        $siblingSectionGroupContent.attr('aria-hidden', !expand);
        $parentSectionGroup.attr('aria-expanded', expand);
        $parent.addClass('active-section-group');
        $mainHeaderNav.addClass('gnav-second-panel');
      }

      $allSectionGroupsTrigger.once().on('click', function () {
        _toggleSectionGroup(this);
      });

      $gnavHamburger.once().on('click', function (e) {
        var $self = $(this);
        var $gnavMainSectionFirst = $gnavMainSectionTrigger.first();
        e.preventDefault();
        e.stopPropagation();
        if (!!isDesktop) {
          return;
        }
        if (!$self.hasClass('site-header-formatter--close-icon')) {
          $gnavHamburgerInput.prop('checked', true);
          _gnavMobileTrigger($gnavMainSectionFirst, e);
        } else {
          $gnavHamburgerInput.prop('checked', false);
          _closeGnav();
        }
      });

      $mainSectionTriggerReset.once().on('click', function () {
        if (isDesktop) {
          _closeGnav();
        }
      });

      $allSectionTriggerResetLabels
        .off('mouseover.siteHeader touchstart.siteHeader')
        .on('mouseover.siteHeader touchstart.siteHeader', function (event) {
          var $self = $(this);
          var $input = $self.prev();
          if (isDesktop && $input.length > 0) {
            if (event.type === 'touchstart') {
              event.preventDefault();
              event.stopPropagation();
            }
            $input.prop('checked', true);
            _toggleSectionGroup($input);
          }
        });

      // Gnav links hover state
      $gnavLinkToutLabel
        .once()
        .on('mouseover.siteHeader', function () {
          var $self = $(this);
          var $parent = $self.parents('.js-gnav-link-tout');
          var $input = $parent.prev();
          var $content = $parent.find('.js-gnav-link-tout-content');
          var $toutHeader = $parent.hasClass('js-gnav-link-tout-header')
            ? $parent
            : $parent.prevAll('.js-gnav-link-tout-header').first();

          $gnavLinkTout.removeClass('active-links-group');
          if ($toutHeader.length > 0) {
            $toutHeader.addClass('active-links-group');
          }

          if (isDesktop && $input.length > 0) {
            $input.prop('checked', true);
            $parent.attr('aria-expanded', 'true');
            $content.attr('aria-hidden', 'false');
          }
        })
        .on('mouseout', function (event) {
          var $parentTout = $(event.currentTarget).parents('.js-gnav-tout-panel');
          var $relatedTarget = $(event.relatedTarget);
          var $relatedTargetParent = $relatedTarget.hasClass('js-gnav-link-tout__section-label')
            ? $relatedTarget.parents('.js-gnav-link-tout')
            : $();

          if ($relatedTargetParent.length > 0 || $parentTout.length > 0) {
            return;
          }
          $gnavLinkTout.removeClass('active-links-group');
        });

      $(document).on('hide_header_offer_banner', function () {
        $template.toggleClass('header-offers-banner-hidden', true);
      });

      $(document).on('check_reset_gnav_radio_buttom', function () {
        _closeGnav();
        $mainSectionTriggerReset.prop('checked', true);
      });

      $gnavSearchIcon.once().on('click', function (e) {
        e.preventDefault();
        e.stopPropagation();
        if (!isDesktop) {
          $gnavHamburgerInput.prop('checked', true);
          _toggleMobileMenu();
          var $trigger = $gnavSearchSection.first().find($gnavMainSectionTrigger);
          _gnavMobileTrigger($trigger, e);
        }
      });

      $(linksToNavigate, $template)
        .not(noTabbableElements)
        .on('keydown', function (event) {
          var $this = $(this);
          var key = event.key;

          if (
            ['ArrowLeft', 'ArrowRight', 'ArrowDown', 'ArrowUp', 'Enter', 'Escape'].indexOf(key) > -1
          ) {
            var $tabbableElements = $(tabbableElements, $template).not(noTabbableElements);
            var index = $tabbableElements.index($this);
            var target;

            $html.addClass('ada-enabled');
            switch (key) {
              case 'ArrowLeft':
                target = index === 0 ? -1 : index - 1;
                break;
              case 'ArrowUp':
                target = index === 0 ? -1 : index - 1;
                break;
              case 'ArrowRight':
                target = index === $tabbableElements.length - 1 ? 0 : index + 1;
                break;
              case 'ArrowDown':
                target = index === $tabbableElements.length - 1 ? 0 : index + 1;
                break;
              case 'Enter':
                if (event.target) {
                  if (
                    event.target.localName === 'a' &&
                    $(event.target).attr('aria-haspopup') === 'true'
                  ) {
                    event.preventDefault();
                  }
                  if ($(event.target).parents('.js-header-offers-banner-formatter-v1').length > 0) {
                    return;
                  }
                  $(event.target).click();
                }

                if (timer) {
                  clearTimeout(timer);
                }
                // If click on label icon then focus the previous element.
                if ($this.attr('for') === 'gnav_section_trigger_') {
                  focusTimer(index - 1);
                }
                // If click on close icon then focus the previous element.
                if ($this.attr('for') === 'gnav_section_trigger_reset') {
                  focusTimer(index - 1);
                }
                // If the link/label has pop up then click to open
                if ($this[0].hasAttribute('aria-haspopup')) {
                  // Focus next element after a time in case it takes time to load
                  focusTimer(index + 1);
                }
                break;
              case 'Escape':
                $(document).trigger('check_reset_gnav_radio_buttom');
                if (timer) {
                  clearTimeout(timer);
                }
                focusTimer(index - 1);
                break;
            }

            if (target !== null && target !== undefined) {
              focusElement(target);
            }
          }
        });

      function focusTimer(index) {
        timer = setTimeout(function () {
          focusElement(index);
        }, 250);
      }

      function focusElement(index) {
        $(tabbableElements, $template).not(noTabbableElements).eq(index, 10).focus();
      }

      $(document).on(
        'mousemove',
        _.debounce(function () {
          // If mouse movement is detected then remove ada class.
          if ($html.hasClass('ada-enabled')) {
            $html.removeClass('ada-enabled');
          }
        }, 200)
      );
    }
  };

  return behavior;
})(jQuery);
